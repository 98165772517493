.mt-12{
	margin-top: 12px;
}
.mt-30{
    margin-top: 30px!important;
}
.mt-46{
    margin-top: 46px;
}

.p-12{
	padding: 12px;
}
.p-lr-12{
	padding: 0 12px;
}
.pb-60{
	padding-bottom: 100px;
}

.br-8{
	border-radius: 8px;
}
.br-12{
	border-radius: 12px;
}

.shadowBox{
    /* box-shadow: 0 8px 12px #ebedf0; */
	box-shadow: 0px 2px 6px 0px rgba(30, 144, 255, .2);
}

.bgWhilt{
    background-color: #fff;
}

/* 内容上下居中 */
.flex_center{
	display: flex;
	align-items: center;
}

/* 页面全屏 */
.full_screen{
    position: fixed;
    box-sizing: border-box;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow:auto;
}

// 弹出层样式
.rv-overlay{
    z-index: 2000!important;
}
// .rv-popup{
//     z-index: 2002!important;
// }